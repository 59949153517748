@import "assets/scss/_imports.scss";

.searchResultsList {

	&-searchterm {
		@include pod-item;

		h2 {
			margin-bottom: 0;
		}
	}
}