@import '../../assets/scss/_imports.scss';

.player {
	margin-bottom: 30px;

	&.withSidebar {
		display: grid;
		grid-template-columns: auto 430px;
		justify-content: center;
		grid-column-gap: 20px;
		padding: 0px 20px;

		@include small {
			display: block;
		}

		@include medium {
			display: block;
		}
	}

	&.withoutSidebar {
		display: flex;
		justify-content: center;
		margin: 0 50px;
		height: 100vh;

		@include small {
			margin: 0 10px;
		}
	}

	&-container {
		background-color: $lightGrey;
		border-radius: 1rem;
		align-self: start;
		min-width: 500px;
		position: sticky;
		top: 20px;
		z-index: 10;

		@include small {
			display: block;
			margin: 0 0.5rem 0.5rem;
			position: relative;
			top: 0;
			min-width: unset;
		}

		@include medium {
			display: block;
			margin: 0 50px 1rem;
			position: relative;
			top: 0;
			min-width: unset;
		}
	}

	.bottom-cap {
		background-color: $lightGrey;
		width: 100%;
		height: 1rem;
		position: relative;
		top: 20px;
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
	}
}
