@import '../../assets/scss/_imports.scss';

$face-size: 26px;

.feedback {
	position: fixed;
	z-index: 999;
	height: 220px;
	background-color: $primary;
	left: 0px;
	bottom: 0px;
	border-top-right-radius: 10px;
	transition: all .5s ease;

	&.small {
		width: 40px;
	}

	&.wide {
		width: 300px;

		@include small {
			width: 99%;
		}
	}

	.feedback-rest,
	.feedback-input,
	.feedback-submitted,
	.feedback-hidden {
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&.active {
			transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
			opacity: 1;
			visibility: visible;
		}
	}

	&-close {
		position: absolute;
		cursor: pointer;
		top: 5px;
		right: 10px;
		color: $white;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease-in-out, visibility .3s ease-in-out, right .3s ease;

		&.active {
			opacity: 1;
			visibility: visible;
		}

		&.close-mobile {
			right: 13px;
		}
	}

	&-rest {
		display: grid;
		grid-template-rows: auto auto;
		justify-content: center;
		margin-top: 10px;

		&-text {
			color: $white;
			writing-mode: vertical-rl;
			margin-bottom: 5px;

			p {
				transform: translateX(-2px);
			}
		}

		&-faces {
			display: grid;
			grid-template-rows: auto auto;
			justify-content: center;
			align-items: center;
			margin-top: 15px;

			&-happy {
				color: $green;
			}

			&-sad {
				color: $red;
			}

			div {
				background: white;
				border-radius: 50%;
				display: flex;
				height: calc(($face-size - 4px) + 2px);
				width: calc(($face-size - 4px) + 2px);
				margin-bottom: 10px;
				cursor: pointer;
				transition: transform .3s ease;

				&:hover {
					transform: scale(1.1);
				}

				svg {
					height: ($face-size - 4px);
					transform: translate(1px, 1px);
				}
			}
		}
	}

	&-input {
		width: 90%;

		&-faces {
			display: grid;
			grid-template-columns: auto auto;
			grid-column-gap: 15px;
			justify-content: center;
			align-items: center;
			margin-bottom: 10px;

			&-happy {
				color: $green;
			}

			&-sad {
				color: $red;
			}

			div {
				background: white;
				border-radius: 50%;
				display: flex;
				height: calc($face-size + 2px);
				width: calc($face-size + 2px);
				margin-bottom: 10px;
				cursor: pointer;
				transition: all .3s ease;
				opacity: .3;

				&.active {
					opacity: 1;
				}

				&:hover {
					transform: scale(1.1);
				}

				svg {
					height: $face-size;
					transform: translate(1px, 1px);
				}
			}
		}

		textarea {
			height: 75px;
			resize: none;
			font-size: 14px;
		}

		&-button {
			display: flex;
			justify-content: center;
			margin-top: 15px;
		}
	}

	&-submitted {
		width: 150px;
		text-align: center;

		h4 {
			color: white;
			margin-bottom: 20px;
		}

		p {
			color: white;
			font-size: 12px;
			margin-bottom: 25px;
		}
	}

	&.minimised {
		width: 80px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		p {
			color: $white;
		}
	}
}
