@import "assets/scss/_imports.scss";

.content-group {
	display: inline-flex;
	flex-wrap: wrap;
	width: fit-content;
	align-content: center;
	background-color: #fff;
	color: #333;
	border: solid 2px $darkGrey;
	border-radius: 22px;
	padding: 8px 15px 10px 45px;
	position: relative;
	font-family: "oxygen", sans-serif;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
	margin: 2px 4px 2px 0;
	cursor: pointer;

	&.disabled {
		user-select: none;
		opacity: .4;
		filter: none;
	}

	&-text {
		z-index: 2;
		position: relative;
		transition: all .5s ease;
	}

	& .cross {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 2px;
		width: 33px;
		border: none;
		border-top-left-radius: 18px;
		border-bottom-left-radius: 18px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		transition: all .5s ease;
		z-index: 1;
		height: 88%;

		svg {
			height: 16px;
			position: absolute;
			top: 50%;
			left: 17px;
			transform: translate(-50%, -50%);
			color: $darkGrey;
		}
	}

	&:hover, &.hovered {
		text-decoration: none;

		.cross {
			width: calc(100% - 4.5px);
			border-top-right-radius: 18px;
			border-bottom-right-radius: 18px;
		}
	}
}





