h1, h2, h3, h4 {
	margin-top: 0;
	margin-bottom: 1rem;
}

h1 {
	font-family: 'oxygen', sans-serif;
	font-size: 1.3rem;
	line-height: 1.4rem;
	font-weight: 600;
}

h2 {
	font-family: 'oxygen', sans-serif;
	font-size: 1.2rem;
	line-height: 1.3rem;
	font-weight: 600;
}

h3 {
	font-family: 'oxygen', sans-serif;
	font-size: 1.1rem;
	line-height: 1.2rem;
	font-weight: 600;
}

h4 {
	font-family: 'oxygen', sans-serif;
	font-size: 1rem;
	line-height: 1.1rem;
	font-weight: 600;
}

p, input, select {
	font-family: 'oxygen', sans-serif;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
}

.button, button {
	font-family: 'oxygen', sans-serif;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
}

@mixin small-text {
	font-size: 0.8rem;
	line-height: 0.9rem;
	font-weight: 400;
}

@mixin font($font-size: 1rem, $font-weight: 400) {
	font-family: 'oxygen', sans-serif;
	font-size: $font-size;
	line-height: calc(#{$font-size} + 0.2rem);
	font-weight: $font-weight;
}


.small-text {
	@include small-text;
}


