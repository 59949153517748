@import "assets/scss/_imports.scss";

.admin-centre {
	@include pod-item;

	.button-container {
		width: 100%;
		margin-top: 1rem;

		a {
			width: 100%;
		}

		&:first-of-type {
			margin-top: 0;
		}
	}
}