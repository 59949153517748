@import "assets/scss/_imports.scss";

.quickSearch {
	@include pod-item;
	position: relative;
	display: grid;
	grid-template-rows: auto auto auto;

	h2 {
		margin: 1.5rem 0;
	}

	.listButton {
		margin-right: 0.2rem;
		margin-bottom: 0.5rem;
		display: inline-block;
	}

	&-list {
		max-height: 130px;
		overflow: hidden;
	}

	&.bentobox {
		grid-template-rows: auto auto 1fr;
	}
}