@import '../../../assets/scss/_imports.scss';

.player-breadcrumb {
	padding: 1.5rem;

	p, a {
		font-size: 16px;
		color: $darkGrey;
		line-height: 20px;

		&:hover {
			color: $darkGrey;
		}

		span {
			font-weight: 700;
		}
	}
}
