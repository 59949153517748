@import '../../assets/scss/_imports.scss';

.dropdown-button-container {
	display: inline-block;
	position: relative;
	font-family: Arial, sans-serif;

	p {
		display: inline-block;
		padding: 15px;
		font-size: 16px;
		font-weight: 800;
		cursor: default;
		width: 100%;
		border-bottom: solid 2px $primary;
	}

	button {
		display: inline-block;
		padding: 10px 20px;
		border-radius: 10px;
		cursor: pointer;
		border: solid 2px $darkGrey;
		background-color: white;
		color: #333;
		font-size: 14px;
		transition: background-color 0.2s, border-color 0.2s;

		&:focus {
			outline: none;
		}

		&:nth-child(1) {
			border-right: none; // Remove right border for the primary button
			border-radius: 4px 0 0 4px;
		}

		&:nth-child(2) {
			border-radius: 0 4px 4px 0;
		}
	}

	.dropdown-button-arrow {
		background-color: $primary;
		color: white;
		padding: 10px 10px;
		display: block;

		&-icon {
			svg {
				transition: all .3s ease;
			}
		}

		&-icon.open {
			svg {
				transform: rotate(-180deg);
			}
		}
	}

	&.disabled {
		cursor: default !important;
		filter: opacity(0.4);
		-webkit-user-select: none;
		user-select: none;
		pointer-events: none;
	}

	.custom-dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 100%;
		border: 1px solid #ccc;
		background-color: #fff;
		border: solid 1px $primary;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
		z-index: 1000;

		div {
			padding: 10px 15px;
			cursor: pointer;
			white-space: nowrap;
			transition: background-color 0.2s;

			&:hover {
				background-color: #f0f0f0;
			}

			&.disabled {
				color: #999;
				cursor: default !important;
			}
		}
	}
}
