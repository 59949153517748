@import '../../../assets/scss/_imports.scss';

.player-media {
	background: $lightGrey;
	padding: 1.5rem;
	padding-top: 0;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;

	&-container {
		background-color: $darkGrey;
		width: 100%;
		height: 100%;
		border-radius: .5rem;
		overflow: hidden;
	}
}

.player-content {
	height: 100%;
}
