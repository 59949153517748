:root {
	/* Font */
	--font: "Roboto",Arial,sans-serif;
	/* Branded colours */
	--primary-colour: #143872; /* 20, 56, 114 */
	--primary-colour-alpha: #143872aa;
	--secondary-colour: #f68b1f; /* 246, 139, 31 */
	/* Hard-coded colours */
	--background-colour: #eee;
	--font-colour: #333;
	--error-colour: #e00;
	--input-border-colour: #aaa;
	--input-focus-colour: #999;
	--disabled-colour: #808080;
	--selectable-hover-colour: #e0e0e0;
	--selectable-highlight-colour: #ccc;
	/* Other settings */
	--hover-brightness: 1.25;
}

html, body {
	color: var(--font-colour);
	font-family: var(--font);
}

body {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0;
}

h1 {
	color: var(--font-colour);
	font-size: 24px;
	margin: 0 0 18px 0;
}

h2 {
	color: var(--font-colour);
	font-size: 20px;
	margin: 12px 0;
}

h3 {
	color: var(--font-colour);
	font-size: 16px;
	margin: 0 0 10px 0;
}

h4 {
	color: var(--font-colour);
	font-size: 14px;
	margin: 6px 0;
}

abbr {
	color: blue;
	cursor: help;
	text-decoration: none;
}

p, td {
	margin: 0;
}

td, tr, iframe {
	overflow: hidden;
}

iframe {
	border: none;
	height: 100%;
	vertical-align: top;
	width: 100%;
}

hr {
	background: #aaa;
	border: 0;
	height: 1px;
	margin: 16px 0;
}

input, textarea, select {
	font-family: var(--font);
}

input {
	color: var(--font-colour) !important;
	outline: none;
}

a {
	color: #0066CC;
	text-decoration: none;
}

	a:hover {
		color: blue;
		text-decoration: underline;
	}

[tabindex] {
	outline: none !important;
}

/* Common Classes */

.centred {
	margin: 0 auto;
	text-align: center;
}

div.flex {
	display: flex;
}

.disabled {
	cursor: default !important;
	filter: brightness(0) opacity(0.5);
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;
}

.unselectable {
	cursor: default !important;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;
}

.screen-reader /* WCAG */ {
	clip: rect(1px, 1px, 1px, 1px);
	height: 0.1px;
	overflow: hidden;
	position: absolute;
	width: 0.1px;
}

.hidden {
	display: none !important;
}

.invisible {
	visibility: hidden;
}

.error-message {
	color: var(--error-colour);
	font-weight: bold;
}

.error-detail {
	color: var(--font-colour);
}

.red {
	color: var(--error-colour);
}

.greyed-out {
	color: #757575;
}

.inline-block {
	display: inline-block;
}

.pt4 {
	padding-top: 4px;
}

.half-size {
	transform: scale(0.5);
	transform-origin: 0 0;
}

*:focus {
	box-shadow: none !important;
}

/* Text measurer */

#ruler {
	visibility: hidden;
}

/* Wait */

#please-wait {
	cursor: none;
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: auto;
	z-index: 998;
}

#please-wait-image {
	left: 50%;
	top: 50%;
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
	-webkit-animation: play 0.5s steps(8) infinite;
	animation: play 0.5s steps(8) infinite;
	z-index: 999;
}

@-webkit-keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}

@-moz-keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}

@-o-keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}

@keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}

/* Scrollable section */

.scrollable-section {
	margin: 12px 0;
	overflow: auto;
}

	.scrollable-section ol {
		margin: 0;
	}


/* No Scroll */

.no-scroll {
	overflow: hidden;
}