@import './colours.scss';
@import './typography.scss';

.p-dropdown-items {
	background-color: white;
	padding-inline-start: 0;
	padding: 5px;
	border: solid 2px $grey;
	border-radius: 5px;
	margin: 0;

	.p-dropdown-item {
		&:hover {
			background-color: $lightGrey;
			border-radius: 5px;
		}
	}
}

.p-checkbox input[type="checkbox"] {
	opacity: 0;
	position: absolute;
	z-index: 2;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0;
	cursor: pointer;
}

.p-checkbox {
	background-color: white;
	border: solid 1px $grey;
	position: relative;
	z-index: 1;
	height: 22px;
	width: 22px;
	border-radius: 5px;
	margin: 0 auto;

	svg {
		vertical-align: unset;
	}

	&-box {
		height: 100%;
		width: 100%;
		text-align: center;
	}
}

.p-multiselect {
	display: inline-flex;
	cursor: pointer;
	user-select: none;

	.p-multiselect-label.p-placeholder {
		color: #6b7280;
	}

	.p-multiselect-trigger {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		background: transparent;
		color: #6b7280;
		width: 2rem;
	}
}

.p-multiselect-panel {
	background: #ffffff;
	color: #4b5563;
	border: 0 none;
	border-radius: 6px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

	.p-multiselect-header {
		display: flex;
		justify-content: space-between;
		padding: 0.75rem 1.25rem;
		border-bottom: 1px solid #e5e7eb;
		color: #374151;
		background: #f9fafb;
		margin: 0;
		border-top-right-radius: 6px;
		border-top-left-radius: 6px;
		height: 50px;

		.p-checkbox {
			display: inline-flex;
			margin: 0;
			vertical-align: sub;
		}

		.p-multiselect-close {
			margin-left: 0.5rem;
			width: 1rem;
			height: 1rem;
			color: #6b7280;
			border: 0 none;
			background: transparent;
			border-radius: 50%;
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		}
	}

	.p-multiselect-items {
		padding: 0;

		.p-multiselect-item {
			margin: 0;
			padding: 0.75rem 1.25rem;
			border: 0 none;
			color: #4b5563;
			background: transparent;
			transition: box-shadow 0.2s;
			border-radius: 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&:not(.p-highlight):not(.p-disabled).p-focus {
				background: #f3f4f6;
			}

			.p-checkbox {
				margin-right: 0.5rem;
			}
		}
	}
}

form {
	input[type=text], input[type=email] {
		padding: 10px;
		border: solid 1px $grey;
		border-radius: 5px;
		line-height: 20px;
		width: 100%;
	}

	label {
		width: 80%;
		text-align: left;

		p {
			@include font(0.8rem);
		}
	}

	select {
		@include font(0.8rem);
		padding: 10px;
		padding-right: 30px;
		border: solid 1px $grey;
		border-radius: 5px;
		line-height: 20px;
		width: 100%;
	}

	option {
		@include font(0.8rem);
	}

	li {
		@include font(0.8rem);
	}
}

.textbox {
	padding: 10px;
	border: solid 1px $grey;
	border-radius: 5px;
	line-height: 20px;
	width: 100%;
}

textarea {
	padding: 10px;
	border: solid 1px $grey;
	border-radius: 5px;
	line-height: 20px;
	width: 100%;
}
