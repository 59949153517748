@import '../../assets/scss/_imports.scss';

.footer {
	position: relative;
	height: 60px;
	display: grid;
	grid-template-columns: 180px 1fr 180px;
	align-content: center;
	padding: 0 20px;

	@include medium {
		grid-template-columns: 80%;
		justify-content: center;
		height: 80px;
	}

	@include small {
		grid-template-columns: 80%;
		justify-content: center;
		height: 80px;
	}

	&:before {
		content: '';
		position: absolute;
		height: 2px;
		top: -2px;
		width: 100%;
		background: linear-gradient(90deg, $white 0%, $lightGrey 5%, $lightGrey 95%, $white 100%);
	}

	&-links {
		justify-self: center;
		display: flex;

		a {
			color: $darkGrey;
			margin: 0 5px;
			text-align: center;

			&:hover {
				color: $darkGrey;
				text-decoration-color: $darkGrey;
			}
		}
	}

	&-logo {
		justify-self: flex-end;

		@include medium {
			justify-self: center;
			margin-top: 15px;
		}

		@include small {
			justify-self: center;
			margin-top: 15px;
		}

		img {
			height: 16px;
			transform: translateY(-2px);
			margin-left: 5px;
		}
	}
}
