@import "assets/scss/_imports.scss";


.searchbox {
	height: 40px;
	position: relative;

	&-input {
		height: 40px;
		width: 100%;
		border: solid 1px $grey;
		border-radius: 20px;
		padding: 0px 40px 0px 20px;
	}

	&-icon {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
}