@import 'assets/scss/_imports.scss';

$numberDimensions: 50px;
$mobileNumberDimensions: 45px;

.nps {
	width: 700px;
	border: solid 1px $grey;
	border-top: none;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background: $white;
	z-index: 999;
	transition: all .5s ease;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.06);

	@include medium {
		left: 0;
		transform: translateX(0);
		border-bottom-left-radius: 0px;
	}

	@include small {
		position: absolute;
		width: 100%;
		top: 100px;
		height: unset;
	}

	&.hide {
		top: -202px;
		visibility: none;
		opacity: 0;
		z-index: -1;
	}

	&-header {
		background: $primary;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		h2 {
			color: $white;
		}

		&-close {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			cursor: pointer;
			color: $white;
		}
	}

	&-body {
		display: grid;
		justify-items: center;

		p {
			margin-top: 20px;
			text-align: center;
			padding: 0 20px;
		}

		&-toptext {
			@include font(14, 600);
		}

		&-countdown {
			@include font(14, 400);
			margin-bottom: 25px;
		}

		&-scale {
			margin: 25px 0px;
			display: grid;
			grid-template-columns: repeat(11, $numberDimensions);

			@include small {
				display: block;
				width: 340px;
				text-align: center;
				margin-bottom: 20px;
			}

			&-number {
				border: solid 1px $grey;
				height: $numberDimensions;
				width: $numberDimensions;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all .3s ease;
				cursor: pointer;

				@include small {
					border-radius: 50%;
					margin-right: 10px;
					display: inline-flex;
					margin-bottom: 10px;
					height: $mobileNumberDimensions;
					width: $mobileNumberDimensions;
				}

				&:hover {
					background: $primary;
					color: $white;
				}

				&:first-of-type {
					border-bottom-left-radius: 50%;
					border-top-left-radius: 50%;
					padding-left: 5px;

					@include small {
						padding-left: 0px;
					}
				}

				&:last-of-type {
					border-bottom-right-radius: 50%;
					border-top-right-radius: 50%;
					padding-right: 5px;

					@include small {
						padding-right: 0px;
					}
				}
			}
		}
	}
}