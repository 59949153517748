@import '../../assets/scss/_imports.scss';

.profile {

	&-details {
		@include pod-item;
		display: grid;
		grid-template-columns: 1fr 3fr;
	}

	.checkbox-row {
		display: grid;
		grid-template-columns: 30px auto;
		justify-items: start;
		margin-top: 10px;
	}

	.consent-intro {
		margin-top: 20px;
	}

	.save {
		margin-top: 20px;
	}
}

