@import "assets/scss/_imports.scss";

.p-datepicker {
	padding: .5rem;
	background: #fff;
	color: #333;
	border: 1px solid #ced4da;
	border-radius: 4px
}

.p-datepicker:not(.p-datepicker-inline) {
	background: #fff;
	border: 0 none;
	box-shadow: 0 2px 12px 0 rgba(0,0,0,.1)
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
	background: #fff
}

.p-datepicker .p-datepicker-header {
	padding: .5rem;
	color: #333;
	background: #fff;
	font-weight: 600;
	margin: 0;
	border-bottom: 1px solid #dee2e6;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px
}

.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
	width: 2rem;
	height: 2rem;
	color: #666;
	border: 0 none;
	background: rgba(0,0,0,0);
	border-radius: 25%;
	transition: background-color .2s,color .2s,box-shadow .2s
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
	color: #343a40;
	border-color: rgba(0,0,0,0);
	background: #e9ecef
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #c7dbff
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
	line-height: 2rem
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
	color: #333;
	transition: background-color .2s,color .2s,box-shadow .2s;
	font-weight: 600;
	padding: .5rem
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
	color: #0e3872
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
	margin-right: .5rem
}

.p-datepicker table {
	font-size: 1rem;
	margin: .5rem 0
}

.p-datepicker table th {
	padding: .5rem
}

.p-datepicker table th > span {
	width: 2.5rem;
	height: 2.5rem
}

.p-datepicker table td {
	padding: .2rem
}

.p-datepicker table td > span {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 25%;
	transition: box-shadow .2s;
	border: 1px solid rgba(0,0,0,0)
}

.p-datepicker table td > span.p-highlight {
	color: black;
	background: #f68b1f;
}

.p-datepicker table td > span:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #c7dbff
}

.p-datepicker table td.p-datepicker-today > span {
	background: #ced4da;
	color: #333;
	border-color: rgba(0,0,0,0)
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
	color: black;
	background: $secondary;
}

.p-datepicker .p-datepicker-buttonbar {
	padding: 1rem 0;
	border-top: 1px solid #dee2e6
}

.p-datepicker .p-datepicker-buttonbar .p-button {
	width: auto
}

.p-datepicker .p-timepicker {
	border-top: 1px solid #dee2e6;
	padding: .5rem
}

.p-datepicker .p-timepicker button {
	width: 2rem;
	height: 2rem;
	color: #666;
	border: 0 none;
	background: rgba(0,0,0,0);
	border-radius: 25%;
	transition: background-color .2s,color .2s,box-shadow .2s
}

.p-datepicker .p-timepicker button:enabled:hover {
	color: #343a40;
	border-color: rgba(0,0,0,0);
	background: #e9ecef
}

.p-datepicker .p-timepicker button:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #c7dbff
}

.p-datepicker .p-timepicker button:last-child {
	margin-top: .2em
}

.p-datepicker .p-timepicker span {
	font-size: 1.25rem
}

.p-datepicker .p-timepicker > div {
	padding: 0 .5rem
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
	border-top: 0 none
}

.p-datepicker .p-monthpicker {
	margin: .5rem 0
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
	padding: .5rem;
	transition: box-shadow .2s;
	border-radius: 4px
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
	color: #143872;
	background: #eef2ff
}

.p-datepicker .p-yearpicker {
	margin: .5rem 0
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
	padding: .5rem;
	transition: box-shadow .2s;
	border-radius: 4px
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
	color: #143872;
	background: #eef2ff
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
	border-left: 1px solid #dee2e6;
	padding-right: .5rem;
	padding-left: .5rem;
	padding-top: 0;
	padding-bottom: 0
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
	padding-left: 0
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
	padding-right: 0;
	border-left: 0 none
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
	background: #e9ecef
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #c7dbff
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
	background: #e9ecef
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #c7dbff
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
	background: #e9ecef
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 .2rem #c7dbff
}

.p-datepicker .p-disabled {
	opacity: .5;
}
