@import "assets/scss/_imports.scss";

.timeline-container {
	@include pod-item;

	.searchbox {
		position: absolute;
		right: 0;
		top: -40px;
		width: 30%;
	}

	.date-column {
		width: 150px;
	}

	.activity-column {
		width: 200px;
	}
}
.timeline-range-input {

	@include medium {
		display: grid;
		grid-template-columns: auto auto;
		grid-row-gap: 10px;
		width: 300px;
	}

	@include small {
		display: grid;
		grid-template-columns: auto auto;
		grid-row-gap: 10px;
		width: 260px;
		margin-bottom: 1rem;
	}

	.timeline-range-from-date {
		.p-inputtext {
			width: 120px;
		}
	}
}

.activity-pill {
	background: white;
	border: 2px solid #808080;
	border-radius: 8px;
	font-weight: bold;
	padding: 4px 0;
	text-align: center;
	width: 150px;

	&.red {
		border-color: $red;
		color: $textColor;
	}

	&.orange {
		border-color: $orange;
	}

	&.yellow {
		border-color: $yellow;
	}

	&.green {
		border-color: $green;
	}

	&.turquoise {
		border-color: $turquoise;
	}

	&.lightBlue {
		border-color: $lightBlue;
	}

	&.blue {
		border-color: $blue;
	}

	&.navy {
		border-color: $navy;
	}

	&.purple {
		border-color: $purple;
	}

	&.violet {
		border-color: $violet;
	}

	&.grey {
		border-color: $grey;
	}
}
