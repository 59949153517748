@import "assets/scss/_imports.scss";

.searchResultItem {
	@include pod-item;

	&-details {
		display: grid;
		grid-template-columns: auto 180px;

		&-text {

			h2 {
				margin-bottom: 0;
				margin-right: 6px;
			}

			&-topline {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;
			}
		}

		&-button {
			justify-self: flex-end;
			align-content: center;
		}

	}

	.searchTerm {
		border-bottom: solid 3px $secondary;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}
