@import '../../assets/scss/_imports.scss';

.list-navigation {

	&-item {
		@include pod-item;

		h2 {
			margin-bottom: 1rem;
		}

		.button-container {
			margin-bottom: 1rem;

			.button {
				width: 100%;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
