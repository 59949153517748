@import "assets/scss/_imports.scss";

.surveyContainer {

	@include small {
		margin: 0 10px 30px;
		padding: 10px;
	}

	span {
		font-family: 'oxygen', sans-serif !important;
	}
	//Buttons

	input[type="button"] {
		font-family: 'oxygen', sans-serif !important;
		transition: all .3s ease;

		&:hover {
			background-color: $primary;
			color: $white !important;
		}
	}
}

.sd-boolean__thumb, .sd-boolean__label {
	color: $darkGrey;
}

.sd-boolean__thumb-ghost {
	cursor: pointer;
	&:hover {
		background-color: $grey !important;
	}
}

//General
.sv-components-row > .sv-components-column--expandable {
	background: $lightGrey;
}

.sd-element--with-frame.sd-question--title-top {
	border-radius: 1rem;
}

.sd-title {
	padding-top: 0 !important;
	font-size: 1.1rem;
	line-height: 1.2rem;
	font-weight: 600;
}

.sd-body {
	background: $lightGrey !important;
}

.sd-description {
	color: $textColor;
}

.sv-components-row {
	background: $white !important;
}

.sd-navigation__complete-btn {
	background-color: $secondary !important;

	&:hover {
		background-color: $primary !important;
	}
}

.sd-body.sd-body--static {
	padding-bottom: calc(10*(var(--sjs-base-unit, var(--base-unit, 4px)))) !important;
	max-width: 100% !important;
}

.sd-root-modern--mobile .sd-body.sd-body--responsive, .sd-root-modern--mobile .sd-body.sd-body--static {
	padding: calc(3*(var(--sjs-base-unit, var(--base-unit, 4px)))) !important;
}

.sd-completedpage {
	padding-top: 30px;

	&::before {
		display: none !important;
	}
}

//Rating

.sd-rating__item:hover {
	cursor: pointer;
	background-color: $grey !important;
}

.sd-rating__item--selected {
	cursor: pointer;
	background-color: $primary !important;
}

//Radio

.sd-radio__decorator {
	transition: all .3s ease;
	background-color: $lightGrey !important;
}

.sd-radio--checked .sd-radio__decorator:after {
	background-color: $white !important;
}

.sd-selectbase__label {
	cursor: pointer;
}

.sd-radio--checked .sd-radio__decorator {
	background-color: $primary !important;
}

//Checkboxes

.sd-checkbox__decorator {
	transition: all .3s ease;
	background-color: $lightGrey !important;
	color: $white !important;
}

.sd-checkbox__decorator svg {
	color: $white !important;
	fill: $white !important;
}

.sd-checkbox--checked .sd-checkbox__decorator {
	background-color: $primary !important;
}

//Completed

.complete-page {
	@include pod-item;
	text-align: center;
	background-color: $lightGrey;
	display: grid;

	h2 {
		margin-bottom: 30px;
	}

	&-feedback {
		@include pod-item;
		justify-self: center;
		padding-bottom: 50px;
		width: 100%;
	}

	&-result {
		@include pod-item;
		width: 100%;
		justify-self: center;

		&-feedback {
			margin-bottom: 20px;
		}

		&-text {
			margin-bottom: 20px;

			span {
				font-size: 15px;
				font-weight: 600;
			}
		}
	}

	&-answers {
		@include pod-item;
		width: 100%;

		&-list {
			margin-bottom: 1rem;
			display: grid;

			&-answer {
				padding: 20px 70px;
				border-radius: 10px;
				margin: 0px auto 20px;
				position: relative;
				overflow: hidden;
				width: 100%;
				max-width: 650px;
				text-align: left;

				p {
					margin-bottom: 10px;
				}

				@include small {
					padding: 20px 20px 20px 70px;
				}

				.answer-icon {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 50px;
					display: flex;
					justify-content: center;
					align-items: center;

					svg {
						height: 30px;
						color: $white;
					}
				}

				&.answer-wrong {
					border: solid 2px $red;

					.answer-icon {
						background-color: $red;
					}

					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.quiz-score {
	font-weight: 600;
	padding-right: 5px;
	font-size: 26px;
}

.quiz-passed {
	.quiz-score {
		color: $green;
	}
}

.quiz-failed {
	.quiz-score {
		color: $red;
	}
}
