.please-wait {
	background-color:rgba(0,0,0,.25);
	cursor: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: auto;
	z-index: 998;
}

.please-wait-image {
	background-image: url('../assets/64-wait.png');
	left: 50%;
	top: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
	animation: play 0.5s steps(8) infinite;
	z-index: 999;
}

@-webkit-keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}

@-moz-keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}

@-o-keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}

@keyframes play {
	from {
		background-position: 0;
	}

	to {
		background-position: -512px;
	}
}