@import "assets/scss/_imports.scss";

.list-view-item {
	@include pod-item;

	h3 {
		margin: 1rem 0;
	}

	&-continue {
		padding: .5rem 1.3rem;
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		border: solid 1px $grey;
		border-radius: 2rem;

		p {
			margin-bottom: 0;
		}

		@include small {
			display: block;
			text-align: center;

			p {
				margin: 4px 0 10px;
			}
		}
	}
}