@import 'assets/scss/_imports.scss';

.contactDetails {
	@include pod-item;
	display: grid;
	grid-template-columns: 50px auto;
	grid-row-gap: 20px;
	align-items: center;

	h3 {
		margin-bottom: 0;
	}
}