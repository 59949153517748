@import 'assets/scss/_imports.scss';

.datatable-pod {
	@include pod-item;

	&-buttons {
		display: flex;
		justify-content: flex-end;

		@include small {
			&:first-of-type {
				margin-bottom: 1.5rem;
			}
		}

		&:last-of-type {
			margin-top: 1.5rem;
		}
	}

	.data-table .p-paginator-bottom {
		padding-bottom: 0;
	}
}

.message-user-list {
	margin: 1rem 0;
	padding: 0;
	list-style: none;
	max-height: 120px;
	overflow-y: auto;

	&-item {
		@include font(1rem, 600);
		padding: .2rem 0;
	}
}


