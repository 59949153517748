@import "assets/scss/_imports.scss";

.data-card {
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 4px;

	.data-card-field {
		margin-bottom: 0.5rem;
	}

	strong {
		font-weight: 600;
	}
}

.data-cards-container {
	display: none;
}

.use-card-view {
	@media (max-width: 768px) {
		.data-cards-container {
			display: block;
			position: relative;

			.searchbox {
				position: relative;
				top: 0;
				margin-bottom: 20px;
				width: 260px;
			}
		}
	}
}
