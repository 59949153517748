@import "assets/scss/_imports.scss";


.list-view-item-item {
	display: grid;
	grid-template-columns: 6rem auto;
	border: solid 1px $grey;
	border-radius: 1rem;
	margin-bottom: 1rem;
	min-height: 120px;
	overflow: hidden;

	@include small {
		display: block;
	}

	&-progress {
		border-right: solid 1px $grey;
		position: relative;


		@include small {
			border-right: none;
			border-bottom: solid 1px $grey;
			padding-bottom: 1rem;
		}

		&-container {
			display: grid;
			grid-template-rows: 60px auto;
			padding-top: 1.6rem;

			@include small {
				margin-top: 1rem;
			}

			p {
				margin-top: .5rem;
				margin-bottom: 0;
				text-align: center;
			}

			&.locked {
				background-color: $grey;
				height: 100%;
				justify-content: center;

				.locked-icon {
					height: 60px;
					width: 60px;
					border: solid 5px $primary;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					justify-self: center;
					align-items: center;

					svg {
						color: $primary;
						height: 22px;
					}
				}
			}
		}
	}

	&-details {
		padding: 1.6rem 1rem;
		align-self: center;

		&-topline {
			display: flex;
			align-items: baseline;
			margin-bottom: 1rem;
			grid-gap: 5px;

			h3, p {
				margin-bottom: 0;
			}
		}

		&-description {
			margin-bottom: 20px;
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}
