@import '../../assets/scss/_imports.scss';

.list-view-item {

	&-details {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-gap: 20px;
		margin-bottom: 1rem;

		a {
			&:hover {
				text-decoration-color: $darkGrey;
			}
		}

		&-text {
			&-description {
				margin-bottom: 1rem;
			}
		}

		&-image {
			img {
				width: 100%;
				border-radius: 1rem;
			}
		}
	}
}