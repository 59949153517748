@import "assets/scss/_imports.scss";

.device-grid {
	display: grid;
	grid-template-columns: 49% 49%;
	grid-column-gap: 2%;
	grid-row-gap: 1rem;
}

.group-name {
	background: $white;
	margin-bottom: 10px;
	padding: 18px 1rem;
	border-radius: 1rem;
}

.control-panel {
	background: $white;
	margin-bottom: 10px;
	padding: 1rem;
	border-radius: 1rem;

	h3 {
		margin: 1rem 0;

		&:first-of-type {
			margin-top: 0;
		}
	}

	.button {
		margin-left: 10px;
	}

	input[type='text'] {
		margin-bottom: .5rem;
	}
}

.selected-device {
	background: $lightGrey;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: auto 20px;
	padding: 18px 1rem;
	border-radius: 1rem;
	margin-top: .5rem;

	&-delete {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20px;
		width: 20px;
	}

	&-button {
		padding: 1.5rem 0 0;
		display: flex;
		justify-content: flex-end;
	}
}

.no-selected-device { 
	height: 80px;
	background-color: $white;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}