@import '../../../assets/scss/_imports.scss';

.player-sidebar {
	$padding: 30px;
	background-color: $lightGrey;
	border-radius: 1rem;

	@include small {
		height: auto;
		border-radius: 1rem;
		margin: 1rem 0.5rem;
	}

	@include medium {
		height: auto;
		border-radius: 1rem;
		margin: 1rem 50px;
	}

	&-header {
		background-color: $primary;
		padding: 1.5rem 1.5rem 1rem 1.5rem;
		color: $white;
		position: relative;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		position: relative;
		overflow: hidden;

		&-image {
			position: absolute;
			max-height: 80%;
			top: 0;
			right: 4px;
			opacity: 0.2;
			z-index: 2;
		}

		h1 {
			position: relative;
			z-index: 2;
			color: $white;
			margin-bottom: .5rem;
			font-size: 20px;
			line-height: 24px;
		}

		&-grad {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(90deg, rgba(0,0,0,.5) 0%, $primary 50%, $primary 100%);
		}
	}

	&-items {
		padding: 1.5rem;
	}

	&-item {
		display: grid;
		grid-template-columns: 5rem auto;
		background-color: $white;
		border: solid 2px $grey;
		border-radius: 1rem;
		margin-bottom: 1rem;
		overflow: hidden;
		cursor: pointer;

		h4 {
			margin-bottom: 0;
		}

		&.selected {
			border: solid 3px $primary;

			&:hover {
				border: solid 3px $primary; /* Stop hover effect taking over active state */
			}

			.player-sidebar-item-button {
				background: $primary;
			}
		}

		&:hover {
			border: solid 2px $primary;
		}

		&-button {
			border-right: solid 1px $grey;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.8rem 0;
		}

		&-details {
			padding: 1rem;
			align-items: center;
			display: flex;

			&-container {
				display: flex;
				align-items: baseline;
				grid-gap: 8px;
			}

			p {
				margin: 0;
			}
		}
	}
}