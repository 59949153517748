@import 'assets/scss/_imports.scss';

.backToTop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
}

.backToTopIcon {
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.3s ease-in-out;
	color: $primary;
	background-color: #fff;
	border-radius: 50%;

	&:hover {
		opacity: 0.7;
	}
}

.backToTopIcon svg {
	width: 100%;
	height: 100%;
}
