@import '../../assets/scss/_imports.scss';

.header-container {
	position: relative;
}

.wide-header {
	padding: 0px 50px;
}

.header {
	padding: 30px 0px;
	display: grid;
	grid-template-columns: auto 45px 310px 60px;
	align-items: center;
	position: relative;
	transition: opacity 0.3s ease, transform 0.3s ease;

	@include small {
		grid-template-columns: auto 45px 35px 45px;
	}

	&.hide {
		opacity: 0;
		transform: translateY(-20px);
		pointer-events: none;
	}

	&-logo {
		height: 35px;
		cursor: pointer;

		@include small {
			height: 26px;
		}

		img, svg {
			height: 100%;
		}
	}

	&-home {

		svg {
			margin-top: 2px;
			height: 26px;
			color: $darkGrey;
			transition: all .3s ease;
			cursor: pointer;
		}

		&:hover {
			svg {
				transform: scale(1.1);
			}
		}
	}

	&-search {
		&-mobile {
			display: none;
			cursor: pointer;

			@include small {
				display: block;
			}

			svg {
				height: 35px;
				color: $darkGrey;

				@include small {
					height: 26px;
				}
			}
		}

		&-desktop {
			@include small {
				display: none;
			}
		}
	}

	&-hamburger {
		position: relative;
		justify-self: end;
		width: 40px;
		height: 40px;
		cursor: pointer;
		z-index: 999;
		overflow: hidden;

		@include small {
			height: 26px;
		}

		&-bar {
			height: 4px;
			width: 40px;
			border-radius: 2px;
			background-color: $darkGrey;
			position: absolute;
			transition: all 0.5s ease;

			@include small {
				height: 3px;
				width: 30px;
			}

			&-one {
				top: 3px;
			}

			&-two {
				top: 17px;
				transition: opacity 0.2s ease;
			}

			&-three {
				top: 31px;
			}

			@include small {
				&-one {
					top: 1px;
				}

				&-two {
					top: 10px;
				}

				&-three {
					top: 19px;
				}
			}
		}

		&-open {

			.header-hamburger-bar {

				&-one {
					transform: rotate(45deg);
					top: 17px;

					@include small {
						top: 10px;
					}
				}

				&-two {
					opacity: 0;
				}

				&-three {
					transform: rotate(-45deg);
					top: 17px;

					@include small {
						top: 10px;
					}
				}
			}
		}
	}
}

.mobileSearch {
	padding: 30px 0px;
	display: grid;
	grid-template-columns: auto 40px;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transform: translateY(-20px);
	pointer-events: none;
	transition: opacity 0.3s ease, transform 0.3s ease;

	&.show {
		opacity: 1;
		transform: translateY(0);
		pointer-events: auto;
	}

	&-close {
		justify-self: flex-end;
		cursor: pointer;
		height: 40px;

		svg {
			height: 100%;
		}
	}
}

.sidebar {
	width: 320px;
	background-color: $white;
	@include boxShadow;
	position: absolute;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	top: -100%;
	right: -20px;
	z-index: 998;
	transition: all 0.5s ease;
	padding: 38px 30px 20px 30px;
	opacity: 0;
	visibility: hidden;

	@include small {
		right: -10px;
	}

	&-open {
		top: 0px;
		opacity: 1;
		visibility: visible;
	}

	&-links {
		margin-top: 40px;

		&-link {
			align-content: center;
			align-items: center;
			display: grid;
			grid-template-columns: 30px auto;

			&-text {
				color: $darkGrey;
				padding: 5px 0px;

				&:hover {
					text-decoration: underline;
					color: $darkGrey;
				}
			}
		}
	}

	.secondary {
		color: $secondary;

		svg {
			color: $secondary;
		}
	}

	h3 {
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 10px;
	}
}
