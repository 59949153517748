@import 'colours.scss';

/* Default Widths */
$small-width: 480px;
$medium-width: 768px;
$large-width: 1024px;

/*Media Query mixins*/
@mixin small {
	@media (max-width: #{$medium-width - 1px}) {
		@content;
	}
}

@mixin medium {
	@media (min-width: #{$medium-width}) and (max-width: #{$large-width - 1px}) {
		@content;
	}
}

@mixin large {
	@media (min-width: #{$large-width}) {
		@content;
	}
}

html, body {
	font-size: 1em; /* This is overwriting the thing in index.css */
}

body {
	width: 100%;
}

a {
	text-decoration: none;
}

.no-mobile {
	@include medium {
		display: none;
	}

	@include small {
		display: none;
	}
}

/* Gutters */
.gutters {
	margin: 0 auto;
	width: 90vw;
	max-width: 1500px;
}

/* Grids */

.grid-two-col {
	display: grid;
	grid-template-columns: 2fr 1fr;
	column-gap: 20px;

	@include small {
		grid-template-columns: auto;
	}

	@include medium {
		grid-template-columns: auto;
	}
}

.grid-full { 

}

.grid-bento { /* Not needed for MVP */
	display: grid;
	grid-template-columns: 2fr 1fr;
	column-gap: 20px;
}

/* Absolute Center */

@mixin abs-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Background Styles */

@mixin pod-item {
	background-color: $white;
	border-radius: 1rem;
	padding: 2.2rem 1.5rem;
	margin-bottom: 1rem;

	@include small {
		padding: 1.8rem 1rem;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}
