@import "assets/scss/_imports.scss";

.p-toast .p-toast-message.p-toast-message-info {
	background: rgba(219, 234, 254, 0.7);
	border: solid #3b82f6;
	border-width: 0 0 0 6px;
	color: #3b82f6;

	&.p-toast-message-icon,
	.p-toast-icon-close {
		color: #3b82f6;
	}
}

.p-toast .p-toast-message {
	backdrop-filter: blur(10px);
	margin: 0 0 1rem 0;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	border-radius: 6px;
}

.p-toast .p-toast-message .p-toast-message-content {
	display: flex;
	align-items: flex-start;
	padding: 1rem;
	border-width: 0 0 0 6px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
	font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
	margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
	width: 2rem;
	height: 2rem;
}