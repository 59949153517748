.timeline-range-input {
	display: flex;
	align-items: baseline;

	.timeline-range-from-date input, .timeline-range-type-select {
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		font-size: 1rem;
		height: 40px;
		margin: 0 12px;
		padding: 0.375rem 0.75rem;

		&:focus {
			border-color: #86b7fe;
		}
	}

	.timeline-range-type-select {
		outline: none;
	}

	.timeline-range-from-date {
		input {
			border-radius: 0.25rem 0 0 0.25rem;
			border-right: none;
			margin-right: 0;
		}

		button {
			background: none;
			border: 1px solid #ced4da;
			border-left: none;
			border-radius: 0 0.25rem 0.25rem 0;
		}
	}
}
