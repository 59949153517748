@import "assets/scss/_imports.scss";

.data-table {

	p-datatable-wrapper {
		overflow: visible;
	}

	.searchbox-input {
		position: absolute;
		right: 0;
		width: 100%;
	}

	tr {
		height: 60px;
		overflow: visible !important; //Needs to overwrite ucskills.scss
		position: relative;
		/* Using nth-child does not work for expanding content */
		&.p-row-odd {
			background-color: $lightGrey;
		}

		& td:first-child, th:first-child {
			padding-left: 1rem;
		}

		& td:last-child, th:last-child {
			padding-right: 1rem;
		}

		&.active {
			background-color: lighten($highlight, 45%);

			&:nth-child(even) {
				background-color: lighten($highlight, 40%);
			}
		}
	}

	table, td, .p-datatable-wrapper {
		overflow: visible !important; //Needs to overwrite ucskills.scss
	}

	tbody {
		border-top: solid 1px $darkGrey;
		border-bottom: solid 1px $darkGrey;
	}

	.p-paginator {
		justify-content: end;
		padding: 1rem;
		column-gap: 5px;

		.p-dropdown {
			margin: 0 10px;
			border: solid 2px $grey;
			padding: 5px;
			border-radius: 5px;

			.p-dropdown-label {
				margin-right: 5px;
			}
		}
	}

	.p-paginator-bottom {
		border-top: solid 1px $darkGrey;
	}

	.p-paginator-page {
		padding: 4px 6px;
		padding-bottom: 3px;
		font-size: 15px;
		transition: all .3s ease;
		margin-left: 3px;
		border-radius: 5px;

		&:first-of-type {
			margin-left: 0px;
		}

		&:hover {
			opacity: (.8);
		}

		&.p-highlight {
			background-color: $primary !important;
			color: $white;
		}
	}

	.p-paginator-current {
		margin-right: 10px;
		cursor: default;
	}

	.header-center {
		div {
			justify-content: center;
		}
	}

	.p-column-header-content {
		column-gap: 6px;
	}
}

.data-table-container {

	.table-header {
		position: relative;

		@include small {
			.searchbox {
				position: relative;
				top: 0;
				margin-bottom: 20px;
				width: 260px;
			}
		}
	}

	.data-table {
		display: block;
	}
}

.use-card-view {
	@media (max-width: 768px) {
		.data-table {
			display: none;
		}
	}

	@media (min-width: 769px) {
		.data-cards-container {
			display: none;
		}
	}
}
