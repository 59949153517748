@import "assets/scss/_imports.scss";

.user-management-modal {

	&-grid {
		display: grid;
		grid-template-columns: 46% 46%;
		grid-column-gap: 8%;
		grid-row-gap: 20px;
		grid-template-rows: auto auto 1fr 1fr;

		@include small {
			display: block;
			width: 84%;

			div {
				margin-bottom: 10px;
			}
		}

		&-colOne {
			grid-column: 1;
		}

		&-colTwo {
			grid-column: 2;
		}

		&-colOneSpanTwo {
			grid-column: 1;
			grid-row: 3 / 5;
		}

		&-colTwoSpanTwo {
			grid-column: 2;
			grid-row: 3 / 5;
		}

		h4 {
			margin-bottom: 10px;
		}

		.contentGroup, .package {
			display: inline-flex;
			grid-template-columns: auto auto auto;
			grid-gap: 4px;
			justify-content: flex-start;

			svg {
				color: $darkGrey;
			}
		}
	}

	.checkbox-list {
		div, p {
			cursor: pointer;
		}

		div {
			margin-bottom: 5px;
		}

		.button {
			margin-top: 20px;
		}

		.mixed {
			opacity: .7;
		}
	}

	.overflowed {
		height: 155px;
		overflow-y: scroll;
		padding: 10px;
		border: solid 1px $lightGrey;
	}
}

.delete-user-list {
	margin: 1rem 0;
	padding: 0;
	list-style: none;
	max-height: 120px;
	overflow-y: auto;
	max-width: 350px;
}

.confirmation-message {
	span {
		@include font(1rem, 600)
	}
}

.modal-content:has(.no-minheight) {
	min-height: initial;
}