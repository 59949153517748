@import '../../assets/scss/_imports.scss';


.percentageCircle {
	position: relative;
	transition: all .5s ease;
	background: $white;
	border-radius: 50%;
	justify-self: center;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		cursor: pointer;
	}
}

.playIcon {
	color: $primary;
	position: absolute;
	height: 18px;
	top: 50%;
	left: 52%;
	transform: translate(-50%, -50%);

	@include small {
		left: 50.5%;
	}

	svg {
		height: 100%;
	}
}

.repeatIcon {
	color: $primary;
	position: absolute;
	height: 18px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}