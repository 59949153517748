@import "assets/scss/_imports.scss";

.contentusage-container {
	@include pod-item;

	.contentgroup-input {
		display: flex;
		align-items: baseline;

		.contentgroup-select {
			border: 1px solid $grey;
			border-radius: 0.25rem;
			font-size: 1rem;
			height: 40px;
			margin: 0 12px;
			padding: 0.375rem 0 0.375rem 0.75rem;
			outline: none;
			min-width: 300px;

			&:focus {
				border-color: #86b7fe;
			}
		}
	}

	.count-column {
		width: 250px;
	}

	.status-column {
		width: 200px;
	}
}

.status-pill {
	color: white;
	border-radius: 8px;
	font-weight: bold;
	padding: 4px 0;
	text-align: center;
	width: 150px;

	&.not-started {
		background-color: $red;
	}

	&.in-progress {
		background-color: $yellow;
		color: $textColor;
	}

	&.completed {
		background-color: $green;
	}
}