@import '../../assets/scss/_imports.scss';

// Header //

.podHeader {
	background-color: $primary;
	padding: 1.5rem 1.5rem 1rem 1.5rem;
	color: $white;
	position: relative;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	position: relative;
	overflow: hidden;

	&-image {
		position: absolute;
		max-height: 80%;
		top: 0;
		right: 4px;
		opacity: 0.2;
		z-index: 2;
	}

	&-image:not(.default) {
		max-height: 70%;
		right: 1rem;
		top: 15%;
	}

	h1 {
		position: relative;
		z-index: 2;
		color: $white;
		margin-bottom: .5rem;
	}

	&-grad {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(90deg, rgba(0,0,0,.5) 0%, $primary 50%, $primary 100%);
	}
}

// Body //

.podBody {
	background-color: $lightGrey;
	padding: 1rem;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	display: grid;
	margin-bottom: 1rem;

	h2 {
		margin-top: 0;
		margin-bottom: 2rem;
	}
}

.sticky {
	position: -webkit-sticky; /* For Safari */
	position: sticky;
	top: 30px;
	align-self: start;
	height: auto;
	min-height: 0;

	@include medium {
		position: relative;
		top: 0;
	}

	@include small {
		position: relative;
		top: 0;
	}
}
