@import "assets/scss/_imports.scss";

.content-groups {
	position: relative;

	&.dropdown-open {

		.content-group {
			z-index: 998;
		}

		.more-groups {
			z-index: 998;
		}

		.overflow-groups-dropdown {
			z-index: 997;
		}
	}
}

.more-groups {
	cursor: pointer;
	height: 32px;
	width: 32px;
	background: $white;
	border: solid 2px $darkGrey;
	display: inline-flex;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	position: relative;
	top: 1px;
}

.overflow-groups-dropdown {
	position: absolute;
	background: $grey;
	border: 1px solid #ccc;
	border-radius: 4px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	top: -8px;
	left: -8px;
	padding: 60px 8px 8px;
	width: calc(100% + 16px);
	min-width: 220px;
	box-shadow: 0px 3px 15px rgba(0,0,0,0.2);

	@include small {
		top: 100%;
		padding: 8px;
	}

	&-groups {
		max-height: 205px;
		overflow-y: auto;
		display: grid;
	}
}

.overflow-groups-dropdown.show {
	opacity: 1;
	visibility: visible;
}
