/* Greyscale */
$white: #fff;
$lightGrey: #eee;
$grey: #ddd;
$darkGrey: #333;
$black: #000;

/* White Labelled Colours (defaults) */
$primary: var(--primary-custom-color);
$secondary: var(--secondary-custom-color);

/* Themes */
$customHex: var(--custom-color);
$backgroundColor: var(--bg-color);
$textColor: var(--text-color);
$siteBackground: var(--site-bg);
$highlight: #0275d8;

/* Timeline Activities */
$red: #ce2323;
$orange: #f68b1f;
$yellow: #ffe104;
$green: #1ea324;
$turquoise: #00af8a;
$lightBlue: #1cdae7;
$blue: #346bd7;
$navy: #152b80;
$purple: #6623ce;
$violet: #cd1ccd;

/* Prime React */
$highlight: #0275d8;

/* Default theme */
:root {
	--primary-custom-color: #888;
	--secondary-custom-color: #888;
	--bg-color: $lightGrey;
	--text-color: $darkGrey;
	--site-bg: $white;
	--primary: $primary;
	--secondary: $secondary;
}

/* Mixin to quickly apply the colours to an element */
@mixin applyThemeColors {
	background-color: $backgroundColor;
	color: $textColor;
}

@mixin boxShadow {
	box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}
