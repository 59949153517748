@import '../../assets/scss/_imports.scss';

.button {
	display: inline-flex;
	flex-wrap: wrap;
	align-content: center;
	cursor: pointer;
	position: relative;

	&.flipped {
		transform: scaleX(-1);

		.button-text {
			transform: scaleX(-1);
		}
	}

	&-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 2px;
		width: 33px;
		border: none;
		border-top-right-radius: 18px;
		border-bottom-right-radius: 18px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		transition: all .5s ease;
		z-index: 1;

		&-icon {
			position: absolute;
			display: flex;
		}
	}

	&-text {
		z-index: 2;
		position: relative;
		transition: all .5s ease;
		white-space: nowrap;
	}

	&:hover {
		text-decoration: none;

		.button-arrow {
			width: calc(100% - 4.5px);
			border-top-left-radius: 18px;
			border-bottom-left-radius: 18px;
		}
	}


	&-primary {
		background-color: $white;
		color: $darkGrey;
		border: solid 2px $secondary;
		border-radius: 22px;
		padding: 8px 50px 10px 15px;
		position: relative;

		.button-arrow {
			background: $secondary;
			height: 88%;

			&-icon {
				color: $darkGrey;
				top: 50%;
				right: 0;
				transform: translate(-50%, -50%);

				svg {
					width: 18px;
					display: flexl;
					justify-items: center;
				}
			}
		}

		&:hover {
			.button-text {
				color: $darkGrey;
			}
		}
	}

	&-secondary, &-delete {
		background-color: $white;
		color: $darkGrey;
		border: solid 2px $primary;
		border-radius: 22px;
		padding: 8px 50px 10px 15px;
		position: relative;

		.button-arrow {
			background: $primary;
			height: 88%;


			&-icon {
				color: $white;
				top: 50%;
				right: 0px;
				transform: translate(-50%, -50%);

				svg {
					width: 18px;
					display: flexl;
					justify-items: center;
				}
			}
		}

		&:hover {
			.button-text {
				color: $white;
			}
		}
	}

	&-tertiary {
		padding: 0px 25px 0px 0px;
		color: $primary;

		.button-arrow-icon {
			top: 55%;
			right: 0;
			transform: translate(-50%, -50%);
			transition: all .5s ease;
		}

		&:hover {
			color: $primary;

			.button-arrow-icon {
				right: -5px;
			}
		}
	}

	&-delete {
		.button-arrow-icon {
			right: 0px;
		}
	}

	&.disabled {
		-moz-user-select: none;
		-ms-user-select: none;
		-webkit-user-select: none;
		user-select: none;
		pointer-events: none;
		filter: none;
		opacity: 0.6;
	}
}

.button-container {
	position: relative;

	.button-tooltip {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateY(calc(-100% - 4px)) translateX(-50%);
		background: $white;
		border: solid 1px $darkGrey;
		border-radius: 5px;
		white-space: nowrap;
		padding: 4px 8px;
		font-size: 14px;
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;

		svg {
			height: 18px;
			position: relative;
			top: 1px;
			padding-right: 2px;
			color: $primary;
		}
	}

	&:hover {
		.button-tooltip {
			opacity: 1;
			visibility: visible;
		}
	}
}

.mini-mobile {
	height: 38px;
	width: 38px;
	padding: 0;

	.button-arrow {
		height: 30px;
		width: 30px;
		border-radius: 50%;

		&-icon {
			right: -3px;
		}
	}

	&:hover {
		text-decoration: none;

		.button-arrow {
			height: 30px;
			width: 30px;
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
		}
	}
}