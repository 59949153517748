@import "assets/scss/_imports.scss";

.device {
	min-width: 300px;
	background: $white;
	padding: 1rem;
	border-radius: 1rem;
	display: grid;
	grid-template-rows: auto 1fr 60px;
	align-items:center;
	justify-items: center;
	border: solid 5px $white;
	cursor: pointer;

	&.selected {
		border-color: $secondary;
	}

	&-image {
		img {
			width: 100%;
		}
	}

}