@import '../../../assets/scss/_imports.scss';

.player-details {
	background: $white;
	border: solid 1px $grey;
	border-top: none;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	padding: 1.5rem;
	padding-top: 2rem;
	position: relative;

	@include small {
		height: unset;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: .5rem;
		background-color: $primary;
		@include boxShadow;
	}


	&-title {
		display: flex;
		align-items: baseline;
		grid-column-gap: .5rem;
	}

	&-parents {
		display: flex;
		align-items: baseline;
		grid-column-gap: 1rem;

		&-parent {
			display: flex;
			align-items: baseline;
			grid-column-gap: .3rem;
		}
	}

	&-navigation {
		display: grid;
		align-items: center;
		grid-template-columns: repeat(3, 1fr);
		height: 50px;

		@include small {
			align-items: flex-start;
			height: unset;
		}

		&-prev {
			justify-self: flex-start;
		}

		&-return {
			justify-self: center;

			@include small {
				position: relative;
				top: -4px;
				margin-top: 50px;
			}
		}

		&-next {
			justify-self: flex-end;
		}
	}

	&.narrow-mode {
		padding-bottom: 60px;

		.player-details-navigation {
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 10px;

			&-prev {
				grid-row: 1;
			}

			&-return {
				grid-column: 1 / 3; 
				grid-row: 2 / 3; 
			}


			&-next {
				grid-row: 1;
			}
		}
	}
}