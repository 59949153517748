@import 'assets/scss/_imports.scss';

.help {

	&-item {
		@include pod-item;

		p {
			margin-top: 1rem;

			&:first-of-type {
				margin-top: 0;
			}
		}
	}

	&-guru {
		@include pod-item;
		display: grid;
		grid-template-columns: 120px auto 160px;
		grid-column-gap: 20px;
		align-items: center;

		@include small {
			grid-template-columns: 100%;
			justify-items: center;
			grid-row-gap: 1rem;
		}

		svg {
			color: $primary;
		}
	}
}
